import { Outlet, NavLink, Link } from "react-router-dom";
import logo from "../../assets/txst-primary.svg";
import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <a href="https://txst.edu" target={"_blank"} title="TXST Home">
                        <img
                            src={logo}
                            alt="Logo"
                            aria-label="Link to TXST Home Page"
                            className={styles.githubLogo}
                            style={{ width: "100px", height: "auto" }} // Adjust the width as needed
                        />
                    </a>
                    <h3 className={styles.headerTitle}>Texas State University Course Catalog Bot</h3>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink
                                    to="mailto:vpit@txstate.edu"
                                    target="_blank"
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                >
                                    Feedback
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
